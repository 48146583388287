import React from 'react';
import { graphql } from 'gatsby';
import Block from '../components/Block';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import goodreadsIcon from '../images/icons/goodreads.svg'
import IGIcon from '../images/icons/ig.svg'

const Index = ({ data, location }) => {
	return (
		<Layout location={location} title="Who's Roman Musatkin?">
			<Block size="small">
				<StaticImage src="../images/roman-2022.png" alt="Roman in Helsinki, 2022" quality={100}></StaticImage><br/><br/>
				<p>The world that's made of nations and borders is a strange place. I live thinking they don't exist.</p>
				<p>I write in Italian, English and Russian, and spend time between Helsinki (where I work), Milan (where family is) and St. Petersburg (the home of my heart).</p>
				<p>I design <a href="https://swarmia.com" target="_blank">Swarmia</a>. Sometimes I talk about design, culture and history.</p>
        
				<p>
					<img src={goodreadsIcon} alt="Goodreads" style={{ position: 'relative', top: '6px'}} />See books I read on  <a href="https://www.goodreads.com/musatkin" target="_blank">Goodreads</a>.<br/>
					<img src={IGIcon} alt="Instagram" style={{ position: 'relative', top: '6px'}} /> Visual diary on  <a href="https://www.instagram.com/roman.musatkin/" target="_blank">Instagram</a>.<br/>
				</p>

				<p>If you enjoy my writing, consider <Link to="/subscribe">Subscribing</Link>.</p>

			</Block>
		</Layout>
	);
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
